var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stage-slide",class:_vm.slideData.customClass,style:(_vm.cssBackground)},[_c('div',{staticClass:"stage-slide__container"},[(_vm.slideData.badge && _vm.slideData.badge.text)?_c('Badge',{staticClass:"stage-slide__badge",class:{ 'stage-slide__badge-legal': _vm.slideData.badgeLegalModal },style:(_vm.badgeStyle),attrs:{"magenta":_vm.slideData.badge.type === 'magenta',"position-default":_vm.slideData.badge.position.default,"position-mobile":_vm.slideData.badge.position.mobile}},[_c('span',{style:(_vm.slideData.badge.text.titleStyle)},[_vm._v(_vm._s(_vm.slideData.badge.text.title))]),_vm._v(" "),_c('div',{style:(_vm.slideData.badge.text.descriptionStyle)},[_vm._v("\n        "+_vm._s(_vm.slideData.badge.text.description)+"\n        "),(_vm.slideData.badge.modal)?_c('LegalModalButton',{staticClass:"stage-slide__badge-modal",style:(_vm.slideData.badge.modal.position[_vm.screenSize]),attrs:{"modal-size":"awardModal"},scopedSlots:_vm._u([{key:"modal",fn:function(){return [_c('div',{staticClass:"stage-slide__badge-modal-text",domProps:{"innerHTML":_vm._s(_vm.slideData.badge.modal.copy)}})]},proxy:true}],null,false,3869109789)}):_vm._e()],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"stage-slide__information",class:_vm.cssTheme},[_c('div',{staticClass:"stage-slide__information-container",class:{
          'stage-slide__information-container--padding-md':
            _vm.slideData.additionalPaddingOnLargeScreen,
        }},[(_vm.isMzaCompatible)?_c('MzaIcon',{staticClass:"stage-slide__mza-icon",class:{
            'stage-slide__mza-icon--magenta': _vm.theme === 'magenta',
          }}):_vm._e(),_vm._v(" "),(_vm.brand.logo)?_c('CdnImageElement',{staticClass:"stage-slide__logo",style:(_vm.logoStyle),attrs:{"src":_vm.brand.logo,"alt":_vm.brand.name,"render-without-figure":""}}):_vm._e(),_vm._v(" "),(_vm.headline)?_c('p',{staticClass:"text stage-slide__headline",domProps:{"innerHTML":_vm._s(_vm.headline)}}):_vm._e(),_vm._v(" "),(_vm.slideData.subline)?_c('p',{staticClass:"text stage-slide__subline",style:(_vm.slideData.subline.style),domProps:{"innerHTML":_vm._s(_vm.slideData.subline.text)}}):_vm._e(),_vm._v(" "),(!_vm.isMobileScreen)?_c('div',{staticClass:"stage-slide__button-container",class:_vm.btnAlignment},[_c('Prices',{staticClass:"stage-slide__prices",class:{
              'stage-slide__prices--light': _vm.theme === 'light',
              'stage-slide__prices--magenta': _vm.theme === 'magenta',
            },attrs:{"is-price-bold":true,"variant":!_vm.isMobileScreen ? 'large' : 'medium',"pre-text-is-price":"","is-price":_vm._f("toHumanPrice")(_vm.defaultPrice),"was-price":_vm._f("toHumanPrice")(_vm.wasPrice)}}),_vm._v(" "),(_vm.slideData.offer)?_c('AddToCartButtonContainer',{staticClass:"stage-slide__add-to-cart",class:_vm.cartBtnTheme,attrs:{"offer":_vm.slideData.offer,"display-icon":false}}):_vm._e(),_vm._v(" "),_c('LinkButton',{class:_vm.ctaBtnTheme,style:(_vm.ctaButton.style),attrs:{"to":_vm.ctaButton.url,"tealium-name":_vm.dataTealiumRel,"tag":"nuxt-link"}},[_vm._v("\n            "+_vm._s(_vm.ctaButton.buttonText)+"\n          ")])],1):_vm._e()],1)]),_vm._v(" "),(_vm.isMobileScreen)?_c('div',{staticClass:"stage-slide__button-container",class:_vm.btnAlignment},[_c('Prices',{staticClass:"stage-slide__prices",class:{
          'stage-slide__prices--light': _vm.theme === 'light',
          'stage-slide__prices--magenta': _vm.theme === 'magenta',
        },attrs:{"is-price-bold":true,"variant":_vm.isAtLeastDesktopScreen ? 'large' : 'medium',"pre-text-is-price":"","is-price":_vm._f("toHumanPrice")(_vm.defaultPrice),"was-price":_vm._f("toHumanPrice")(_vm.wasPrice)}}),_vm._v(" "),(_vm.slideData.offer)?_c('AddToCartButtonContainer',{staticClass:"stage-slide__add-to-cart",class:_vm.cartBtnTheme,attrs:{"offer":_vm.slideData.offer,"display-icon":false,"theme":"primary"}}):_vm._e(),_vm._v(" "),_c('LinkButton',{class:_vm.ctaBtnTheme,attrs:{"to":_vm.ctaButton.url,"tealium-name":_vm.dataTealiumRel,"tag":"nuxt-link"}},[_vm._v("\n        "+_vm._s(_vm.ctaButton.buttonText)+"\n      ")])],1):_vm._e(),_vm._v(" "),(_vm.slideData.legal)?_c('LegalModalButton',{staticClass:"stage-slide__legal-modal",style:(_vm.slideData.legal.position[_vm.screenSize]),attrs:{"modal-size":"awardModal"},scopedSlots:_vm._u([{key:"modal",fn:function(){return [_c('h4',[_vm._v(_vm._s(_vm.slideData.legal.heading))]),_vm._v(" "),_c('p',{staticClass:"stage-slide__modal-text"},[_vm._v("\n          "+_vm._s(_vm.slideData.legal.copy)+"\n        ")]),_vm._v(" "),_c('ul',{staticClass:"stage-slide__modal-text stage-slide__modal-list"},_vm._l((_vm.slideData.legal.list),function(item){return _c('li',{key:item.link.url},[_vm._v("\n            "+_vm._s(item.text)+"\n            "),_c('a',{attrs:{"href":item.link.url,"target":"_blank","rel":"noopener"}},[_vm._v(_vm._s(item.link.label))])])}),0)]},proxy:true}],null,false,2322299744)}):_vm._e(),_vm._v(" "),(_vm.hasEnergyLabel)?_c('EnergyLabelContainer',{staticClass:"stage-slide__energy-label",style:(_vm.slideData.energyLabelPosition[_vm.screenSize]),attrs:{"product":_vm.slideData.offer.product}}):_vm._e()],1),_vm._v(" "),(_vm.slideData.aktionsBanner)?_c('div',{staticClass:"stage-slide__aktion-banner"},[_c('FlyerAktionBanner',{attrs:{"text":"Telekom"}}),_vm._v(" "),_c('FlyerAktionBanner',{staticClass:"stage-slide__aktion-banner-offset",attrs:{"text":"Flyer-Aktion"}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }