<template>
  <div class="categories-list">
    <div class="categories-list__content-wrapper">
      <NavCategory
        v-for="(category, index) in categories"
        :key="`smallCategories-${index}`"
        :category="category"
      />
    </div>
    <LinkButton
      tag="button"
      transparent
      borderless
      class="categories-list__button"
      @click="openShopMenu"
    >
      alle Kategorien anzeigen&nbsp;&nbsp;
      <SvgIcon class="icon categories-list__icon" :image="plusIcon" />
    </LinkButton>
  </div>
</template>

<script>
import NavCategory from '@/components/nav-category';
import plusIcon from '@/assets/images/plus.svg';
import { mapGetters } from 'vuex';
import { LinkButton } from '@i22-td-smarthome/component-library';

export default {
  name: 'CategoriesList',
  components: { NavCategory, LinkButton },
  props: {
    specificOrder: {
      type: Object,
      default: () => ({
        thermostate: 0,
        beleuchtung: 1,
        'tuer-fensterkontakte': 2,
        zwischenstecker: 3,
      }),
    },
    limit: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
      categories: null,
      plusIcon,
    };
  },
  computed: {
    ...mapGetters('navigation', ['getCategories']),
  },
  mounted() {
    // these categories should be shown before any other
    function sortObjectsBySpecificOrder(objects, specificOrder) {
      return objects.sort((a, b) => {
        const aIndex = specificOrder[a.path];
        const bIndex = specificOrder[b.path];
        if (aIndex === undefined && bIndex === undefined) {
          return 0;
        } else if (aIndex === undefined) {
          return 1;
        } else if (bIndex === undefined) {
          return -1;
        } else {
          return aIndex - bIndex;
        }
      });
    }

    this.categories = sortObjectsBySpecificOrder([...this.getCategories], this.specificOrder).slice(
      0,
      this.limit,
    );

    this.categories.forEach((category) => {
      import(`@/assets/images/${category.path}.svg`)
        .then((icon) => {
          this.$set(category, 'icon', icon.default);
        })
        .catch(() => {
          console.error(`Cannot find icon for category ${category.path}`);
        });
    });
  },
  methods: {
    openShopMenu() {
      this.$nuxt.$emit('open-menu', 'Shop');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/base';

.categories-list {
  width: 100%;
  margin-block: 4 * $grid-base;

  &__content-wrapper {
    display: flex;
    gap: $grid-base;
    flex-direction: row;
    justify-content: space-evenly;
    @include mq(false, $mq-sm) {
      width: 100%;
      flex-wrap: wrap;
      gap: 6px 0;
    }
  }
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $grid-base;
    color: $color-link;
    margin: 2 * $grid-base auto 0 auto;
    &:hover {
      background: none;
    }
  }
  &__icon {
    stroke-width: 2;
    font-size: 20px;
    margin-right: $grid-base;
  }
  @include mq($mq-md, false) {
    :deep(.nav-category__category-link) {
      border: 1px solid rgb(220, 220, 220);
      border-radius: 9px;
      justify-content: center;
    }
  }
}
</style>
