<template>
  <div class="brands-showcase">
    <div class="brands-showcase__container" :style="`--speed: ${speed};`">
      <component
        :is="brand.slug ? 'nuxt-link' : 'span'"
        v-for="(brand, index) in mappedBrands"
        :key="index"
        :to="`shop/alle-kategorien/${brand.slug}`"
        class="brands-showcase__logo"
      >
        <CdnImageElement
          :alt="brand.alt"
          :src="brand.src"
          class="brands-showcase__image"
          :class="`brands-showcase__image--${brand.slug}`"
          render-without-figure
        />
      </component>
    </div>
  </div>
</template>

<script>
import CdnImageElement from '@/components/cdn-image-element';
import brandLogos from '@/assets/01-settings/js/brand-logos.json';

export default {
  name: 'BrandsShowcase',
  components: {
    CdnImageElement,
  },
  props: {
    customSpeed: {
      type: String,
      default: null,
    },
    brands: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      brandLogos,
    };
  },
  computed: {
    speed() {
      return this.customSpeed || `${Math.max(10, this.brands.length)}s`;
    },
    mappedBrands() {
      const brands = this.brands
        .map(({ brand }) => {
          if (!this.brandLogos[brand.slug]) {
            return false;
          }
          const logoObject = this.brandLogos[brand.slug];
          if (!logoObject.unique) {
            return null;
          }

          // for telekom logo we want custom slug showing also magentatv and smarthome products
          const slug = brand.name === 'Telekom' ? 'magentatv+smarthome+telekom' : brand.slug;
          return {
            src: logoObject.logoColor || logoObject.logoBw,
            alt: brand.name,
            slug,
          };
        })
        .filter(Boolean);
      // double it, so there will be enough elements on big screens;
      return [...brands, ...brands];
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/base';
.brands-showcase {
  width: 100%;
  overflow: hidden;
  margin-top: $grid-base;
  margin-bottom: 6 * $grid-base;

  &__container {
    display: inline-flex;
    grid-gap: $grid-base * 2;
    will-change: translate;

    animation: animate-logos 35s linear infinite;
    animation-duration: var(--speed);
    backface-visibility: hidden;
    perspective: 1000px;

    @keyframes animate-logos {
      from {
        transform: translate3d(-50%, 0, 0);
      }
      to {
        transform: translate3d(0, 0, 0);
      }
    }
  }
  &__logo {
    height: 60px;
    border: 1px solid rgb(220, 220, 220);
    border-radius: 9px;
    padding: $grid-base $grid-base * 2;
    width: 168px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__image {
    height: 34px;
    width: 118px;

    &--eurotronic {
      width: 52px;
    }

    &--aura {
      height: 25px;
    }

    &--imou {
      height: 28px;
    }
  }
}
</style>
