import { render, staticRenderFns } from "./service-bar.vue?vue&type=template&id=9faaa57a&scoped=true&"
import script from "./service-bar.vue?vue&type=script&lang=js&"
export * from "./service-bar.vue?vue&type=script&lang=js&"
import style0 from "./service-bar.vue?vue&type=style&index=0&id=9faaa57a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9faaa57a",
  null
  
)

export default component.exports