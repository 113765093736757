import baseGoliathRequest from './base-goliath-request';

export default async (params = {}) => {
  const {
    offers: {
      aggregations: { brands },
    },
  } = await baseGoliathRequest('/api/goliath/product-brands', params, {
    offers: { aggregations: { brands: [] } },
  });

  return brands;
};
