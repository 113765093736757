<template>
  <grid-container class="mzapp-teaser">
    <Heading font-weight="ultra" align="center" tag="h2" size="md" class="mzapp-teaser__header">
      MagentaZuhause App
    </Heading>
    <grid-row
      class="mzapp-teaser__row"
    >
      <grid-column :sm="12" :md="6" class="mzapp-teaser__column--first">
        <grid-row
          class="mzapp-teaser__row"
        >
          <grid-column :no-padding="true" :sm="12" :md="8" class="mzapp-teaser__column-content">
            <div>
              <Heading font-weight="ultra" size="xs" :align="isAtLeastDesktopScreen ? 'left' : 'center'" tag="h3" class="mzapp-teaser__section-header">
                Eine App für alles
              </Heading>
              <span>
                Steuere smarte Geräte verschiedener Hersteller mit nur einer App und automatisiere dein smartes Zuhause. Jetzt kostenlos starten!
              </span>
            </div>
            <div class="desktop-only">
              <MzaQrModal transparent class="mzapp-teaser__cta-button mzapp-teaser__cta-button--qr" />
              <LinkButton
                class="mzapp-teaser__cta-button"
                primary
                tag="nuxt-link"
                to="/magentazuhause-app"
              >Mehr zur App</LinkButton>
            </div>
          </grid-column>
          <grid-column :no-padding="true" :sm="12" :md="4" class="mzapp-teaser__column-image">
            <CdnImageElement
              alt="Magentazugase App Screen"
              src="/api/content/images/7f385e5226e22a88e119579433b3dc3b/mza-homescreen-05-2024@2x.png"
              class="mzapp-teaser__image mzapp-teaser__image--first"
              render-without-figure
              lazy
            />
          </grid-column>
          <grid-column :no-padding="true" :sm="12" class="mzapp-teaser__column-content mzapp-teaser__column-content--border mobile-only">
            <div>
              <GetApp />
              <LinkButton
                class="mzapp-teaser__cta-button mzapp-teaser__cta-button--more-info"
                primary
                tag="nuxt-link"
                to="/magentazuhause-app"
                :is-wide="true"
              >Mehr zur App
              </LinkButton>
            </div>
          </grid-column>
        </grid-row>
      </grid-column>
      <grid-column :sm="12" :md="6" class="mzapp-teaser__column--second">
        <grid-row
          class="mzapp-teaser__row"
        >
          <grid-column :no-padding="true" :sm="12" :md="6" class="mzapp-teaser__column-content">
            <div>
              <Heading font-weight="ultra" size="xs" :align="isAtLeastDesktopScreen ? 'left' : 'center'" tag="h3" class="mzapp-teaser__section-header">
                Kompatible Geräte kaufen
              </Heading>
              <span>
                Entdecke alle Geräte, die mit der MagentaZuhause App der Telekom kompatibel sind.
              </span>
            </div>
            <div class="desktop-only">
              <LinkButton
                class="mzapp-teaser__cta-button"
                primary
                tag="nuxt-link"
                to="/shop"
              >Zum Shop</LinkButton>
            </div>
          </grid-column>
          <grid-column :no-padding="true" :sm="12" :md="6" class="mzapp-teaser__column-image">
            <div class="mzapp-teaser__overlay-conteiner">
              <CdnImageElement
                alt="Magentazugase App Screen"
                src="/api/content/images/d8c2053418ae55ac62dfb597c97f7a7d/mza-kompatible-geraete@2x.png"
                class="mzapp-teaser__image mzapp-teaser__image--second"
                lazy
                render-without-figure
              />
              <div class="mzapp-teaser__overlay">
                <SvgIcon
                  class="icon mzapp-teaser__icon"
                  :image="mzaIcon"
                />
                kompatibel mit der <br> MagentaZuhause App
              </div>
            </div>
          </grid-column>
          <grid-column :no-padding="true" :sm="12" class="mzapp-teaser__column-content mobile-only">
            <div>
              <LinkButton
                class="mzapp-teaser__cta-button"
                primary
                tag="nuxt-link"
                to="/shop"
              >Zum Shop</LinkButton>
            </div>
          </grid-column>
        </grid-row>
      </grid-column>
    </grid-row>
  </grid-container>
</template>

<script>
import {
  GridContainer,
  GridRow,
  GridColumn,
  Heading,
  LinkButton,
  GetApp,
} from '@i22-td-smarthome/component-library';
import MzaQrModal from './mza-qr-modal';
import CdnImageElement from '@/components/cdn-image-element';
import mzaIcon from '@/assets/images/mza-relaunch/mza-logo-magenta-solid.svg';
import Responsive from '@/mixins/responsive';

export default {
  name: 'MagentazugaseAppTeaser',
  components: {
    GridContainer,
    GridRow,
    GridColumn,
    Heading,
    LinkButton,
    GetApp,
    MzaQrModal,
    CdnImageElement,
  },
  mixins: [Responsive],
  data() {
    return {
      mzaIcon,
    };
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/base';

.mzapp-teaser {
  border: 1px solid rgb(220, 220, 220);
  border-radius: 9px;
  font-size: 24px;
  line-height: 29px;
  padding: $grid-base * 2 $grid-base;
  max-width: calc(100% - 2 * $grid-base);
  margin-bottom: $grid-base * 2;
  @include mq(false, $mq-sm) {
    padding: unset;
    padding-top: $grid-base * 2;
  }
  &__header {
    margin-bottom: $grid-base * 2;
  }
  &__section-header {
    font-size: 24px;
    margin-bottom: $grid-base;
  }
  &__column {
    &--second {
      border-left: 1px solid rgb(220, 220, 220);
      padding-left: 2 * $grid-base;
      @include mq(false, $mq-sm) {
        padding: 0;
      }
      @include mq(false, $mq-md) {
        border-left: unset;
      }
    }
  }
  &__cta-button {
    display: inline-block;
    margin-top: $grid-base;
    &--qr {
      margin-right: $grid-base;
    };
    &--more-info {
      margin-bottom: $grid-base * 3;
       @include mq(false, $mq-sm) {
        margin-bottom: $grid-base;
      }
    }
  }
  &__column-content {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    @include mq(false, $mq-md - 1) {
      text-align: center;
    }
    &--border {
      border-bottom: 1px solid rgb(220, 220, 220);
      margin-bottom: 2 * $grid-base;
    }
  }
  &__column-image {
    justify-content: center;
    display: flex
  }
  &__image {
    max-width: 100%;
    height: auto;
    &--first {
      height: 270px;
      aspect-ratio: 350/559;
       @include mq($mq-sm, $mq-md -1) {
          height: 350px;
       }
       @include mq(false, $mq-sm) {
          height: 230px;
       }
    }
    &--second {
      min-width: 150px;
      max-height: 260px;
      aspect-ratio: 7/5;
      margin-bottom: 15px;
      @include mq(false, $mq-sm) {
        height: 240px;
      }
    }
  }
  &__overlay-conteiner {
    position:relative;
    height: 270px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mq($mq-sm, $mq-md) {
      height: 320px;
    }
  }
  &__overlay {
    position: absolute;
    left: 15px;
    bottom: 0px;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    font-size: 15px;
    line-height: 18px;
    @include mq($mq-md) {
      left: 38px;
    }
  }
  &__icon {
    width: 90px;
    height: auto;
    margin-right: $grid-base;
  }
  .desktop-only {
    display: none;
    @include mq($mq-md, false) {
      display: block;
    }
  }
  .mobile-only {
    display: none;
    @include mq(false, $mq-md - 1) {
      display: block;
    }
  }
}
</style>
