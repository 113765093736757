<template>
  <div
    class="service-bar"
  >
    <div class="service-bar__container">
      <div class="service-bar__service">
        <SvgIcon
          class="service-bar__icon"
          :image="magentaAppOutlineIcon"
        />
        MagentaZuhause App: Eine App für alles
      </div>
      <div class="service-bar__service">
        <SvgIcon
          class="service-bar__icon"
          :image="customerServiceIcon"
        />
        24/7 Kundenservice & Expertenberatung
      </div>
      <div class="service-bar__service">
        <SvgIcon
          class="service-bar__icon"
          :image="packageIcon"
        />
        Kostenloser Versand ab 50 Euro
      </div>
    </div>
  </div>
</template>

<script>
import magentaAppOutlineIcon from '@/assets/images/mza-relaunch/mza-logo-magenta-outline.svg';
import customerServiceIcon from '@/assets/images/customer-service.svg';
import packageIcon from '@/assets/images/package.svg';

export default {
  name: 'ServiceBar',
  data: () => ({
    magentaAppOutlineIcon,
    customerServiceIcon,
    packageIcon,
  }),
};

</script>

<style scoped lang="scss">
@import 'assets/base';
.service-bar {
  display: flex;
  justify-content: center;
  @include mq(false, 750px) {
    display: none;
  }
  &__service {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: calc($grid-base / 2);
  }
  &__icon {
    height: 12px;
    width: 12px;
  }
  &__container {
    flex-direction: row;
    display: flex;
    justify-content: space-evenly;
    color: #262626;
    font-size: 12px;
    padding: calc($grid-base / 2);
    grid-gap: $grid-base * 4
  }
}
</style>
