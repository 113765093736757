<template>
  <div class="stage-slider">
    <client-only>
      <vue-glide
        ref="slider"
        v-model="activeIndexSlide"
        :autoplay="autoplay"
        :bullet="moreThanOneSlide"
        :class="sliderTheme"
        :per-view="1"
        class="stage-slider__slider"
        type="slider"
        :animation-duration="0"
        :gap="0"
      >
        <vue-glide-slide
          v-for="(entry, i) in stageSlidesData"
          :key="i"
          :class="entry.customClass"
          class="stage-slider__slider-item"
        >
          <StageSlide :slide-data="entry" />
        </vue-glide-slide>
        <template v-if="moreThanOneSlide" slot="control">
          <button class="stage-slider__button stage-slider__button--left" data-glide-dir="<">
            <SvgIcon
              :class="getArrowTheme('left')"
              :image="arrowPrevIcon"
              class="icon stage-slider__icon stage-slider__icon--left"
            />
          </button>
          <button class="stage-slider__button stage-slider__button--right" data-glide-dir=">">
            <SvgIcon
              :class="getArrowTheme('right')"
              :image="arrowNextIcon"
              class="icon stage-slider__icon stage-slider__icon--right"
            />
          </button>
        </template>
      </vue-glide>
    </client-only>
  </div>
</template>

<script>
import arrowNextIcon from '@/assets/images/icon-arrow-right.svg';
import arrowPrevIcon from '@/assets/images/icon-arrow-left.svg';
import StageSlide from '@/components/startpage/slides/stage-slide';
import { Glide, GlideSlide } from 'vue-glide-js';
import 'vue-glide-js/dist/vue-glide.css';

export default {
  name: 'StageSlider',
  components: {
    StageSlide,
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
  },
  props: {
    stageSlidesData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      arrowNextIcon,
      arrowPrevIcon,
      theme: null,
      sliderButtonThemes: null,
      activeIndexSlide: 0,
      autoplay: 5000,
    };
  },
  computed: {
    moreThanOneSlide() {
      return this.stageSlidesData.length > 1;
    },
    sliderTheme() {
      if (!this.theme) return null;
      return `stage-slider__slider--${this.theme}`;
    },
  },
  updated() {
    this.getActiveTheme();
  },
  methods: {
    getActiveTheme() {
      this.stageSlidesData.forEach((element, index) => {
        if (this.activeIndexSlide === index) {
          this.sliderButtonThemes = element.customSliderButtonThemes || null;
          this.theme = element.theme;
        }
        return undefined;
      });
    },
    getArrowTheme(direction) {
      if (!this.theme && !this.sliderButtonThemes) return null;
      if (this.sliderButtonThemes) {
        if (direction === 'left') return `stage-slider__icon--${this.sliderButtonThemes.left}`;
        else return `stage-slider__icon--${this.sliderButtonThemes.right}`;
      }
      return `stage-slider__icon--${this.theme}`;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'assets/base';

.stage-slider {
  margin-bottom: 2 * $grid-base;
  height: 460px + 2 * $grid-base;
  @include mq(false, $mq-sm - 1) {
    height: 480px + 2 * $grid-base;
  }
  &__slider-item {
    height: inherit;
  }
  &__button {
    background: transparent;
    border: none;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }

  &__icon {
    height: 40px;
    width: 20px;
    transition: filter linear 1000ms;
    &--light {
      filter: brightness(0) invert(1);
    }
  }

  :deep([data-glide-el='controls']) {
    position: absolute;
    width: 100%;
    top: 50%;
    display: none;
    @include mq(500px) {
      display: flex;
    }
    @include mq($mq-extra-large) {
      padding: 0 24px;
    }
  }
  :deep(.stage-slider__button) {
    position: absolute;
    transform: translateY(-50%);
  }
  :deep(.stage-slider__button--right) {
    right: 6px;
    @include mq($mq-extra-large) {
      right: 24px;
    }
  }
  :deep(.stage-slider__button--left) {
    left: 6px;
    @include mq($mq-extra-large) {
      left: 24px;
    }
  }

  :deep([data-glide-el='controls[nav]']) {
    margin-top: $grid-base;
    text-align: center;

    .glide__bullet {
      background: #a6a6a6;
      height: 2px;
      width: 40px;
      margin-right: 6px;
      border: none;

      &:hover {
        cursor: pointer;
      }

      &:focus {
        outline: none;
      }

      &--active {
        height: 4px;
        background: $color-primary;
      }
    }
  }

  // fade in/out effect for glide js lib
  // https://github.com/glidejs/glide/issues/279#issuecomment-468204570
  :deep(.glide__slides) {
    // important needed because this is set using the style attribute in glide js
    transform: none !important;
    display: grid;
  }
  :deep(.glide__slide) {
    grid-row: 1;
    grid-column: 1;
    opacity: 0;
    transition: opacity 1s;
  }
  :deep(.glide__slide--active) {
    opacity: 1;
    z-index: 1;
  }
}
</style>
