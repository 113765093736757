<template>
  <div class="flyer-aktion__container">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'FlyerAktionBanner',
  props: {
    text: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/base';

.flyer-aktion {
  &__container {
    position: relative;
    display: inline-block;
    padding: 0 18px 0 18px;
    overflow: hidden;
    color: #fff;
    font-size: 18px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    text-align: end;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: color(magenta, 1000);
      transform-origin: 100% 0;
      transform: skew(-20deg);
      z-index: -1;
    }
  }
}
</style>
