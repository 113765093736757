<template>
  <main class="startpage">
    <ServiceBar />
    <div v-if="stageSlidesData && stageSlidesData.length > 0">
      <StageSlider :stage-slides-data="stageSlidesData" />
    </div>
    <heading class="startpage__heading" align="center" size="lg" tag="h1" spaces-bottom>
      Deine Smart Home Produkte aus einer Hand
    </heading>
    <!-- Changed the structure for Oct 11 promotions -->
    <GridContainer>
      <div class="startpage__container startpage__container-top">
        <div
          v-for="offer in mainOffers"
          :key="offer.slug"
          class="startpage__container-entry"
          :class="{ 'startpage__custom-offer': offer.useOfferLightCustom }"
          :style="offer.customStyle"
        >
          <OfferLightCustom v-if="offer.useOfferLightCustom" v-bind="offer" heading-tag="h2" />
          <OfferLight v-else v-bind="offer" heading-tag="h2" />
        </div>
      </div>

      <GridRow>
        <CategoriesList />
        <BrandsShowcase :custom-speed="'30s'" :brands="brands" />
      </GridRow>
    </GridContainer>
    <GridContainer>
      <div class="startpage__container startpage__container-bottom">
        <div
          v-for="offer in bottomOffers"
          :key="offer.slug"
          class="startpage__container-entry"
          :style="offer.customStyle"
        >
          <OfferLight v-bind="offer" heading-tag="h2" />
        </div>
      </div>
      <GridRow>
        <MagentazuhaseAppTeaser />
      </GridRow>
    </GridContainer>
  </main>
</template>

<script>
import OfferLight from '@/components/offer-light';
import OfferLightCustom from '@/components/offer-light-custom';
import ServiceBar from '@/components/service-bar';
import CategoriesList from '@/components/categories-list';
import BrandsShowcase from '@/components/brands-showcase';
import MagentazuhaseAppTeaser from '@/components/magentazuhase-app-teaser';
import StageSlider from '@/components/startpage/stage-slider';
import { getPrice } from '@i22/rocket';

import { Heading, GridContainer, GridRow } from '@i22-td-smarthome/component-library';

// Mix imports
import PageTracking from '@/mixins/page-tracking';
import PageHeader from '@/mixins/page-header';
import Responsive from '@/mixins/responsive';

import fetchOffers from '@/lib/goliath/offers';
import fetchProductBrands from '@/lib/goliath/product-brands';
import contentDataFactory from '@/lib/content-data-factory';
import { attachmentThumbUrl } from '@/lib/attachment-route';

function mapGoliathOffers(predefiniedOffers, offersFromGoliath) {
  return predefiniedOffers
    .map((productTeaser) => {
      if (!productTeaser.articleNumber) {
        return productTeaser;
      }
      const offer = offersFromGoliath.entries?.find(
        (o) => o.product.articleNumber === productTeaser.articleNumber,
      );
      if (!offer) {
        return false;
      }
      return {
        ...productTeaser,
        offer,
        image: attachmentThumbUrl(offer.teaserImage),
        hero: productTeaser.hero,
        title: productTeaser.title || offer.product.name,
        priceWas: getPrice(offer.prices, 'WAS', 'ONETIME')?.value,
        priceDefault: getPrice(offer.prices, 'DEFAULT', 'ONETIME')?.value,
      };
    })
    .filter(Boolean);
}

export default {
  name: 'HomeView',
  components: {
    Heading,
    OfferLight,
    OfferLightCustom,
    ServiceBar,
    CategoriesList,
    BrandsShowcase,
    MagentazuhaseAppTeaser,
    GridContainer,
    GridRow,
    StageSlider,
  },
  mixins: [PageHeader, PageTracking.Default, Responsive],
  layout: 'default',
  async asyncData(context) {
    // eslint-disable-next-line prefer-const
    let {
      SLIDES_DATA,
      PRODUCT_TEASERS_ADDITIONAL,
      PRODUCT_TEASERS_BOTTOM,
      // eslint-disable-next-line prefer-const
      PRODUCT_TEASER_AD,
      PRODUCT_TEASERS_MAIN,
    } = await contentDataFactory()(context);
    if (!SLIDES_DATA) SLIDES_DATA = [];
    if (!PRODUCT_TEASERS_ADDITIONAL) PRODUCT_TEASERS_ADDITIONAL = [];
    if (!PRODUCT_TEASERS_BOTTOM) PRODUCT_TEASERS_BOTTOM = [];
    if (!PRODUCT_TEASERS_MAIN) PRODUCT_TEASERS_MAIN = [];

    let brands = [];
    let stageSlidesData = [];
    let mainOffers = [];
    let bottomOffers = [];
    try {
      brands = await fetchProductBrands({ filter: {} });
      const offersFromGoliath = await fetchOffers({
        filter: {
          articleNumbers: [
            ...PRODUCT_TEASERS_MAIN,
            ...PRODUCT_TEASERS_BOTTOM,
            ...PRODUCT_TEASERS_ADDITIONAL,
            ...SLIDES_DATA,
          ]
            .map((item) => item.articleNumber)
            .filter(Boolean),
        },
      });
      stageSlidesData = mapGoliathOffers(SLIDES_DATA, offersFromGoliath);
      mainOffers = mapGoliathOffers(PRODUCT_TEASERS_MAIN, offersFromGoliath);
      bottomOffers = mapGoliathOffers(PRODUCT_TEASERS_BOTTOM, offersFromGoliath);

      // if we run out of stock for some item, add additional offers
      const mainOffersFetchdDiff = PRODUCT_TEASERS_MAIN.length - mainOffers.length;
      const bottomOffersFetchedDiff = PRODUCT_TEASERS_BOTTOM.length - bottomOffers.length;
      if (mainOffersFetchdDiff !== 0 || bottomOffersFetchedDiff !== 0) {
        const additionalOffers = mapGoliathOffers(PRODUCT_TEASERS_ADDITIONAL, offersFromGoliath);
        for (let i = 0; i < mainOffersFetchdDiff && additionalOffers.length; i += 1) {
          mainOffers.push(additionalOffers.pop());
        }
        for (let i = 0; i < bottomOffersFetchedDiff && additionalOffers.length; i += 1) {
          bottomOffers.push(additionalOffers.pop());
        }
      }

      // Add offer with ad
      if (PRODUCT_TEASER_AD) {
        mainOffers.push(PRODUCT_TEASER_AD);
      }
    } catch (error) {
      console.error(error);
    }
    return {
      mainOffers,
      bottomOffers,
      brands,
      stageSlidesData,
      SLIDES_DATA,
      PRODUCT_TEASERS_BOTTOM,
    };
  },
  data() {
    return {
      pageMeta: {
        title: 'SmartHome entdecken',
        type: 'theme.landing',
        description:
          'Mehr Sicherheit und Komfort für dein Zuhause: SmartHome ist besonders einfach einzurichten und mit vielen Herstellern kompatibel. Jetzt entdecken!',
        og: {
          type: 'website',
          description:
            'Mehr Sicherheit und Komfort für dein Zuhause: SmartHome ist besonders einfach einzurichten und mit vielen Herstellern kompatibel. Jetzt entdecken!',
          image: '/api/content/images/og-images/245111fc7a009e1e5d5ecfa3d0d110a3/og-home.jpg',
        },
        exagon: {
          description:
            'Mehr Sicherheit und Komfort für dein Zuhause: SmartHome ist besonders einfach einzurichten und mit vielen Herstellern kompatibel. Jetzt entdecken!',
        },
      },
    };
  },
  computed: {
    tealiumPageContentId() {
      return 'home';
    },
  },
};
</script>

<style lang="scss">
@import 'assets/base';

.startpage {
  &__heading {
    padding-bottom: $grid-base;
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $grid-base * 2;
    margin-inline: $grid-base;
    margin-bottom: $grid-base * 2;

    @include mq($mq-sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    &-entry {
      max-width: none;
      display: inherit;
      grid-area: var(--grid-area-xs, auto);

      @include mq($mq-sm) {
        grid-area: var(--grid-area-sm, auto);
      }
      @include mq($mq-md) {
        grid-area: var(--grid-area-md, var(--grid-area-sm, var(--grid-area-xs, auto)));
      }
    }

    &-top {
      @include mq($mq-md) {
        grid-template-columns: repeat(4, 1fr);
      }
    }

    &-bottom {
    }
  }
}
</style>
