<template>
  <div class="smarthome-award">
    <CdnImageElement
      class="smarthome-award__img"
      alt="SmartHome Award"
      :src="'/api/content/images/award/2d9978262f1ef1e9408518c54247b502/world-smart-home-awards-winner@2x.png'"
    />
    <LegalModalButton class="smarthome-award__modal" modal-size="awardModal">
      <template #modal>
        <h4>{{ heading }}</h4>
        <p class="smarthome-award__modal-text">
          {{ copy }}
        </p>
        <ul class="smarthome-award__modal-text smarthome-award__modal-list">
          <li v-for="item in list" :key="item.link.url">
            {{ item.text }}
            <a :href="item.link.url" target="_blank" rel="noopener">{{
              item.link.label
            }}</a>
          </li>
        </ul>
      </template>
    </LegalModalButton>
  </div>
</template>

<script>
import CdnImageElement from './cdn-image-element';
import LegalModalButton from './legal-modal-button';


export default {
  name: 'SmartHomeAward',
  components: {
    CdnImageElement,
    LegalModalButton,
  },
  props: {
    heading: {
      type: String,
      default: 'SIEGER WORLD SMARTHOME AWARD',
    },
    copy: {
      type: String,
      default: 'Unsere smarten Produkte wurden 2022 mit dem World Smart Home Award ausgezeichnet, darunter:',
    },
    list: {
      type: Array,
      default: () => [
        {
          text: 'SmartHome WLAN LED-Lampe E27 farbig (Kategorie Smart lights and lamps)',
          link: {
            label: 'Mehr erfahren',
            url: 'https://worldsmarthomeawards.com/en/winner/1403-Telekom-SmartHome%20WLAN%20LED-Lampe%20E27%20farbig',
          },
        },
        {
          text:
                'SmartHome Tür-/Fensterkontakt optisch (Kategorie Security / Smart door and window sensors)',
          link: {
            label: 'Mehr erfahren',
            url: 'https://worldsmarthomeawards.com/en/winner/1401-Telekom-SmartHome%20T%C3%BCr--Fensterkontakt%20optisch',
          },
        },
        {
          text: 'SmartHome Home Base 2 (Kategorie Smart home retrofit systems)',
          link: {
            label: 'Mehr erfahren',
            url: 'https://worldsmarthomeawards.com/en/winner/1400-Telekom-SmartHome%20Home%20Base%202',
          },
        },
        {
          text: 'SmartHome WLAN Zwischenstecker innen (Kategorie Smart sockets)',
          link: {
            label: 'Mehr erfahren',
            url: 'https://worldsmarthomeawards.com/en/winner/1402-Telekom-SmartHome%20Home%20Base%202',
          },
        },
      ],
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/base';

.smarthome-award {
  display: flex;
  flex-direction: row;
  align-items: end;
  &__img {
    width: 40px;
  }
  &__modal {
    margin-bottom: 8px;
    &-text {
      margin-bottom: $grid-base;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-list {
      margin-left: 2 * $grid-base;
    }
  }
}
</style>
