<template>
  <div :class="slideData.customClass" :style="cssBackground" class="stage-slide">
    <div class="stage-slide__container">
      <Badge
        v-if="slideData.badge && slideData.badge.text"
        class="stage-slide__badge"
        :class="{ 'stage-slide__badge-legal': slideData.badgeLegalModal }"
        :magenta="slideData.badge.type === 'magenta'"
        :style="badgeStyle"
        :position-default="slideData.badge.position.default"
        :position-mobile="slideData.badge.position.mobile"
      >
        <span :style="slideData.badge.text.titleStyle">{{ slideData.badge.text.title }}</span>
        <div :style="slideData.badge.text.descriptionStyle">
          {{ slideData.badge.text.description }}
          <LegalModalButton
            v-if="slideData.badge.modal"
            :style="slideData.badge.modal.position[screenSize]"
            class="stage-slide__badge-modal"
            modal-size="awardModal"
          >
            <template #modal>
              <div class="stage-slide__badge-modal-text" v-html="slideData.badge.modal.copy" />
            </template>
          </LegalModalButton>
        </div>
      </Badge>
      <div :class="cssTheme" class="stage-slide__information">
        <div
          class="stage-slide__information-container"
          :class="{
            'stage-slide__information-container--padding-md':
              slideData.additionalPaddingOnLargeScreen,
          }"
        >
          <MzaIcon
            v-if="isMzaCompatible"
            class="stage-slide__mza-icon"
            :class="{
              'stage-slide__mza-icon--magenta': theme === 'magenta',
            }"
          />
          <CdnImageElement
            v-if="brand.logo"
            class="stage-slide__logo"
            :src="brand.logo"
            :alt="brand.name"
            :style="logoStyle"
            render-without-figure
          />
          <p v-if="headline" class="text stage-slide__headline" v-html="headline" />
          <p
            v-if="slideData.subline"
            class="text stage-slide__subline"
            :style="slideData.subline.style"
            v-html="slideData.subline.text"
          />
          <div v-if="!isMobileScreen" :class="btnAlignment" class="stage-slide__button-container">
            <Prices
              class="stage-slide__prices"
              :class="{
                'stage-slide__prices--light': theme === 'light',
                'stage-slide__prices--magenta': theme === 'magenta',
              }"
              :is-price-bold="true"
              :variant="!isMobileScreen ? 'large' : 'medium'"
              pre-text-is-price=""
              :is-price="defaultPrice | toHumanPrice"
              :was-price="wasPrice | toHumanPrice"
            />
            <AddToCartButtonContainer
              v-if="slideData.offer"
              :offer="slideData.offer"
              :display-icon="false"
              class="stage-slide__add-to-cart"
              :class="cartBtnTheme"
            />
            <LinkButton
              :class="ctaBtnTheme"
              :to="ctaButton.url"
              :tealium-name="dataTealiumRel"
              tag="nuxt-link"
              :style="ctaButton.style"
            >
              {{ ctaButton.buttonText }}
            </LinkButton>
          </div>
        </div>
      </div>
      <div v-if="isMobileScreen" :class="btnAlignment" class="stage-slide__button-container">
        <Prices
          class="stage-slide__prices"
          :is-price-bold="true"
          :class="{
            'stage-slide__prices--light': theme === 'light',
            'stage-slide__prices--magenta': theme === 'magenta',
          }"
          :variant="isAtLeastDesktopScreen ? 'large' : 'medium'"
          pre-text-is-price=""
          :is-price="defaultPrice | toHumanPrice"
          :was-price="wasPrice | toHumanPrice"
        />
        <AddToCartButtonContainer
          v-if="slideData.offer"
          :offer="slideData.offer"
          :display-icon="false"
          theme="primary"
          class="stage-slide__add-to-cart"
          :class="cartBtnTheme"
        />
        <LinkButton
          :class="ctaBtnTheme"
          :to="ctaButton.url"
          :tealium-name="dataTealiumRel"
          tag="nuxt-link"
        >
          {{ ctaButton.buttonText }}
        </LinkButton>
      </div>
      <LegalModalButton
        v-if="slideData.legal"
        :style="slideData.legal.position[screenSize]"
        class="stage-slide__legal-modal"
        modal-size="awardModal"
      >
        <template #modal>
          <h4>{{ slideData.legal.heading }}</h4>
          <p class="stage-slide__modal-text">
            {{ slideData.legal.copy }}
          </p>
          <ul class="stage-slide__modal-text stage-slide__modal-list">
            <li v-for="item in slideData.legal.list" :key="item.link.url">
              {{ item.text }}
              <a :href="item.link.url" target="_blank" rel="noopener">{{ item.link.label }}</a>
            </li>
          </ul>
        </template>
      </LegalModalButton>
      <EnergyLabelContainer
        v-if="hasEnergyLabel"
        :product="slideData.offer.product"
        class="stage-slide__energy-label"
        :style="slideData.energyLabelPosition[screenSize]"
      />
    </div>
    <div v-if="slideData.aktionsBanner" class="stage-slide__aktion-banner">
      <FlyerAktionBanner text="Telekom" />
      <FlyerAktionBanner text="Flyer-Aktion" class="stage-slide__aktion-banner-offset" />
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import { Badge, Prices, LinkButton } from '@i22-td-smarthome/component-library';
import Responsive from '@/mixins/responsive';
import CdnImageElement from '@/components/cdn-image-element';
import AddToCartButtonContainer from '@/components/product-detail/add-to-cart-button-container';
import { getPrice } from '@i22/rocket';
import MzaIcon from '@/components/mza-icon';
import LegalModalButton from '@/components/legal-modal-button';
import EnergyLabelContainer from '@/components/energy-label-container';
import FlyerAktionBanner from '@/components/flyer-aktion-banner';
import { getBrand } from '@/lib/brand';

export default {
  name: 'StageSlide',
  components: {
    Badge,
    LinkButton,
    Prices,
    AddToCartButtonContainer,
    CdnImageElement,
    MzaIcon,
    LegalModalButton,
    EnergyLabelContainer,
    FlyerAktionBanner,
  },
  mixins: [Responsive],
  props: {
    slideData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    cssBackground() {
      if (!this.slideData.background) return 'background: #FFFFFF';
      const bgImage = this.slideData.background.image;
      const bgImageCSS = [];
      if (bgImage) {
        if (bgImage.srcSmall) bgImageCSS.push(`--bg-image-xs: url(${bgImage.srcSmall});`);
        if (bgImage.srcMedium) bgImageCSS.push(`--bg-image-sm: url(${bgImage.srcMedium});`);
        if (bgImage.srcLarge) bgImageCSS.push(`--bg-image-md: url(${bgImage.srcLarge});`);
        if (this.slideData.background.position) {
          bgImageCSS.push(`background-position: ${this.slideData.background.position};`);
        }
        return bgImageCSS.join('');
      }
      return `background: ${this.slideData.background.color};`;
    },
    btnAlignment() {
      if (!this.isMobileScreen) return 'stage-slide__button-container--left';
      return 'stage-slide__button-container--center';
    },
    theme() {
      if (typeof this.slideData.theme === 'object') {
        return this.slideData.theme[this.screenSize] || this.slideData.theme.default || 'dark';
      }
      return this.slideData.theme || 'dark';
    },
    cssTheme() {
      if (this.theme === 'light') return 'stage-slide__information--light';
      else if (this.theme === 'magenta') return 'stage-slide__information--magenta';
      return null;
    },
    defaultPrice() {
      if (!this.slideData.offer) return undefined;
      return getPrice(this.slideData.offer.prices, 'DEFAULT', 'ONETIME')?.value;
    },
    wasPrice() {
      if (!this.slideData.offer) return undefined;
      return getPrice(this.slideData.offer.prices, 'WAS', 'ONETIME')?.value;
    },
    brand() {
      let brand = getBrand(this.slideData.offer);
      if (this.slideData.brand) {
        if (!brand) {
          brand = this.slideData.brand;
        } else {
          brand = { ...brand, ...this.slideData.brand };
        }
      }
      return brand;
    },
    headline() {
      if (this.slideData.headline) return this.slideData.headline.text;
      if (!this.slideData.offer) return undefined;
      return this.slideData.offer.product.name;
    },
    ctaButton() {
      const defaultButton = {
        buttonText: 'Zum Angebot',
        url: `/geraete/${get(this.slideData, 'offer.product.slug')}`,
      };
      return {
        ...defaultButton,
        ...this.slideData.ctaButton,
      };
    },
    dataTealiumRel() {
      if (!this.ctaButton.buttonText) return undefined;
      return `content.button.${this.ctaButton.buttonText}`;
    },
    badgeStyle() {
      let specificStyle;
      if (this.slideData.badge?.style?.[this.screenSize]) {
        specificStyle = this.slideData.badge.style[this.screenSize];
      }
      return { ...this.slideData?.badge?.style, ...specificStyle };
    },
    logoStyle() {
      const style = this.slideData?.brand?.style;
      if (!style) return undefined;
      if (typeof style === 'string') return style;

      if (style[this.screenSize]) {
        return { ...style, ...style[this.screenSize] };
      }

      return style;
    },
    productTags() {
      if (!this.slideData.offer?.product) return [];
      let tags = [];
      if (this.slideData.offer.product.bundledProducts?.length > 0) {
        const { bundledProducts } = this.slideData.offer.product;

        tags = bundledProducts[0].tags.filter((tag) =>
          bundledProducts.every((product) => product.tags.includes(tag)));
      } else if (this.slideData.offer.product.tags) {
        tags = this.slideData.offer.product.tags;
      }
      return tags.map((str) => str.toLowerCase());
    },
    isMzaCompatible() {
      return ['mzabasic', 'mzapro'].some((tag) => this.productTags.includes(tag));
    },
    hasEnergyLabel() {
      if (this.slideData.offer?.product?.bundledProducts?.length > 0) {
        return this.slideData.offer?.product?.bundledProducts.some(
          (product) => product.energyEfficiency?.labelImage,
        );
      }
      return this.slideData.offer?.product?.energyEfficiency?.labelImage;
    },
    ctaBtnTheme() {
      if (this.ctaButton.theme) {
        return this.isMobileScreen ? this.ctaButton.theme.mobile : this.ctaButton.theme.default;
      }
      switch (this.theme) {
        case 'light':
          return 'stage-slide__button stage-slide__button--light btn--transparent-light';
        case 'magenta':
          return 'stage-slide__button stage-slide__button--magenta';
        case 'custom':
          return 'stage-slide__button stage-slide__button--custom btn--transparent';
        default:
          return 'stage-slide__button stage-slide__button--dark btn--transparent';
      }
    },
    cartBtnTheme() {
      if (!this.slideData.cartButton?.theme) return '';
      return this.isMobileScreen
        ? this.slideData.cartButton.theme.mobile
        : this.slideData.cartButton.theme.default;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/base';

.stage-slide {
  position: relative;
  display: flex;
  flex-direction: row;
  align-content: center;
  text-align: center;
  justify-content: center;
  min-height: 350px;
  width: 100%;
  background-image: var(--bg-image-xs);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 480px;
  @include mq($mq-sm) {
    background-image: var(--bg-image-sm, var(--bg-image-xs));
    justify-content: flex-start;
    text-align: left;
    height: 460px;
  }
  @include mq($mq-md) {
    background-image: var(--bg-image-md, var(--bg-image-sm, var(--bg-image-xs)));
  }

  &__logo {
    height: 24px;
    margin: $grid-base 0;
    @include mq(false, $mq-sm - 1) {
      margin-bottom: 8px; // space for MZA Icon
      height: 18px;
      width: 80px;
    }
    @include mq($mq-sm) {
      margin-top: $grid-base * 0.5;
    }
  }

  &__mza-icon {
    position: absolute;
    top: $grid-base;
    right: $grid-base;
    @include mq($mq-sm) {
      top: 4 * $grid-base;
      right: 3 * $grid-base;
    }
    @include mq($mq-xl) {
      right: 158px;
    }

    &--magenta {
      :deep(.mza__icon) {
        border: 2px solid color(white);
        border-radius: 6px;
      }
    }
  }

  &__aktion-banner {
    position: absolute;
    left: 0px;
    bottom: 30%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 50%;
    @include mq($mq-medium) {
      bottom: 32px;
    }
    @include mq($mq-large) {
      width: 30%;
    }

    &-offset {
      margin-right: 10px;
      @include mq($mq-large) {
        margin-right: 40px;
      }
    }

    :deep(.flyer-aktion__container) {
      @include mq($mq-medium) {
        font-size: 36px;
        font-style: italic;
        font-weight: 500;
        line-height: 48px;
        padding: 0 20px 0 80px;
      }
    }
  }

  &__badge {
    position: absolute;
    font-weight: 800;
    z-index: 2;
    @include mq($mq-sm) {
      @include font-size(21px, 21px);
    }
    @include mq($mq-md) {
      @include font-size(24px, 24px);
    }
    @include font-size(18px, 18px);

    &-legal-modal {
      margin: 0;
      border: 1.2px solid white;
      height: 11px;
      z-index: 2;

      :deep(.legal-modal-button__button) {
        line-height: 20px;

        &:hover {
          background-color: transparent;
        }

        &:focus {
          background-color: transparent;
        }
      }
    }

    &-legal {
      :deep(.badge__content) {
        width: 105%;
      }
    }

    &-modal-text {
      overflow-wrap: break-word;
    }
  }

  &__container {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: end;
    position: relative;
    @include mq(375px) {
      max-width: 400px;
    }
    @include mq($mq-sm) {
      flex-direction: row;
      max-width: 90%;
    }
    @include mq($mq-large) {
      width: 100%;
      max-width: 95%;
      padding: 0 2 * $grid-base;
    }
  }

  &__button-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    &--left {
      text-align: left;
      justify-content: flex-start;
    }

    &--center {
      text-align: center;
      justify-content: center;
    }

    @include mq(false, $mq-sm - 1) {
      flex-direction: column;
      align-items: center;
      padding-bottom: $grid-base;
      gap: calc($grid-base / 2);
    }
  }

  &__button--custom {
    color: color(black);
    border-color: color(black);
    @include mq($mq-sm) {
      color: color(white);
      border-color: color(white);
      background-color: transparent;
    }
  }

  &__button--magenta {
    color: color(white);
    background-color: color(magenta, 1000);
    font-weight: 700;
  }

  &__headline {
    font-weight: 800;
    @include font-size(24px, 29px);
    margin-bottom: calc($grid-base / 2);
    @include mq($mq-sm) {
      @include font-size(36px, 36px);
      margin-bottom: $grid-base;
    }
    @include mq($mq-large) {
      @include font-size(48px, 48px);
    }
  }

  &__subline {
    word-break: break-word;
    margin-bottom: $grid-base;
    @include font-size(18px, 24px);
    font-weight: 700;
    @include mq($mq-sm) {
      @include font-size(21px, 25px);
      font-weight: 800;
    }
    @include mq($mq-large) {
      @include font-size(24px, 29px);
    }
  }

  &__information {
    display: flex;
    z-index: 1;
    position: relative;
    color: color(grey, 12000);
    height: 100%;

    &--light,
    &--magenta {
      color: color(white);
    }

    @include mq($mq-sm) {
      width: 50%;
      padding-left: $grid-base;
    }

    @include mq($mq-md) {
      padding-left: 4 * $grid-base;
    }

    &-container {
      width: 100%;
      padding-left: $grid-base;
      margin: auto;
      @include mq(false, $mq-sm - 1) {
        padding: unset;
        margin: unset;
      }

      &--padding-md {
        @include mq($mq-md) {
          padding-left: 4 * $grid-base;
        }
      }
    }
  }

  &__legal-modal {
    z-index: 2;
    position: absolute;
    bottom: 18%;
    left: -$grid-base;
  }

  &__modal {
    &-text {
      margin-bottom: $grid-base;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-list {
      margin-left: 2 * $grid-base;
    }
  }

  &__prices {
    &--light {
      color: color(white);
    }

    :deep(.prices__main) {
      justify-content: start;
      flex-direction: row-reverse;
    }

    &--magenta {
      color: color(black);
      @include mq($mq-sm) {
        color: color(white);
      }

      :deep(.price__amount--highlight) {
        color: color(magenta, 1000);
        @include mq($mq-sm) {
          color: color(white);
        }
      }
    }

    &--start-set-mobile {
      :deep(.price--variant-medium) {
        color: color(black);
      }
    }

    width: 100%;
    padding-bottom: 0;
    @include mq(false, $mq-sm) {
      width: fit-content;
      :deep(.price__amount--highlight) {
        @include font-size(24px, 29px);
        font-weight: 800;
      }
    }
    @include mq($mq-sm, $mq-md) {
      :deep(.price__amount--highlight) {
        @include font-size(36px, 36px);
      }
    }
  }

  &__energy-label {
    z-index: 2;
    position: absolute;
    @include font-size(36px, 36px);
    @include mq($mq-sm) {
      @include font-size(48px, 48px);
    }

    :deep(.energy-label-datasheet) {
      display: none;
    }
  }

  :deep(.xl-only) {
    display: none;
    @include mq($mq-xl, false) {
      display: block;
    }
  }

  &__add-to-cart--magenta {
    border-color: color(white);
  }
}
</style>
