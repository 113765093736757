<template>
  <div
    class="offer-light offer-light__background"
    :style="background"
    :class="[{ 'offer-light__advertisement': isAdvertisement }, `offer-light--${customClass}`]"
  >
    <a :href="link" class="offer-light__content-container">
      <div class="offer-light__heading">
        <div
          v-if="brand.logo"
          class="offer-light__brand"
          :class="{ 'offer-light__brand--mza': isMzaCompatible }"
        >
          <CdnImageElement
            :src="brand.logo"
            :alt="brand.name"
            render-without-figure
            lazy
            class="offer-light__logo"
          />
        </div>
        <heading
          class="offer-light__title"
          :tag="headingTag"
          size="md"
          font-weight="ultra"
          align="center"
        >
          {{ title }}
        </heading>
        <MzaIcon v-if="isMzaCompatible" class="offer-light__mza-icon" />
      </div>
      <div
        class="offer-light__content"
        :class="{ 'offer-light__advertisement--content': isAdvertisement }"
      >
        <div v-if="showFlyerAktionBanner" class="offer-light__banner">
          <FlyerAktionBanner text="Telekom" />
          <FlyerAktionBanner text="Flyer-Aktion" class="offer-light__banner-offset" />
        </div>
        <div
          v-if="hero || image"
          class="offer-light__hero"
          :class="{ 'offer-light__hero--product': !hero }"
        >
          <CdnImageElement
            class="offer-light__hero-img"
            :style="heroCustomStyle"
            :src="hero || image"
            :alt="title"
            lazy
            render-without-figure
          />
          <EnergyLabelContainer
            v-if="product"
            class="offer-light__hero-energy-label"
            :product="product"
            datasheet-alignment="justify"
            :style="energyLabelPosition"
          />
          <SmarthomeAward v-if="showSmartHomeAward" />
        </div>

        <div v-else class="offer-light__hero-placeholder"></div>
        <div>
          <ProductTileBadge
            v-if="rebateDescription"
            :content="rebateDescription"
            :custom-badge-name="rebateName"
            :footnote-content="footnoteText"
            class="offer-light__backend-badge"
          />

          <Badge
            v-else-if="customBadgeMessage || priceWas"
            class="offer-light__badge"
            :position-default="{ bottom: 90, [badgePosition]: '17%' }"
            :position-mobile="{ bottom: 66, [badgePosition]: '10%' }"
          >
            <span
              v-if="customBadgeMessage"
              class="offer-light__badge--custom-message"
              v-html="customBadgeMessage.text"
            >
            </span>
            <div v-else class="offer-light__badge--rebate">
              <span v-if="!isDiscountEven">über <br /> </span>
              <span class="offer-light__badge--highlight"> {{ discount }}</span><br />
              sparen
            </div>
          </Badge>
        </div>

        <Prices
          v-if="priceDefault"
          class="offer-light__prices"
          :style="priceCustomStyle"
          pre-text-is-price="nur"
          :is-price="humanizePrice(priceDefault, { hideTrailingZeros: true })"
          :was-price="priceWas ? humanizePrice(priceWas) : ''"
        />
      </div>
    </a>
  </div>
</template>

<script>
import { Heading, Prices, Badge } from '@i22-td-smarthome/component-library';

import CdnImageElement from '@/components/cdn-image-element';
import MzaIcon from '@/components/mza-icon';
import { humanizePrice } from '@i22/rocket';
import SmarthomeAward from './smarthome-award';
import EnergyLabelContainer from './energy-label-container';
import FlyerAktionBanner from './flyer-aktion-banner';
import { getBrand } from '@/lib/brand';
import ProductTileBadge from '@/components/product-detail/product-tile-badge';

export default {
  name: 'OfferLight',
  components: {
    Heading,
    CdnImageElement,
    Prices,
    Badge,
    MzaIcon,
    SmarthomeAward,
    EnergyLabelContainer,
    FlyerAktionBanner,
    ProductTileBadge,
  },
  props: {
    title: { type: String, required: true },
    hero: { type: String, required: false, default: '' }, // hero image can be integrated with background
    image: { type: String, default: '' },
    heroCustomStyle: { type: String, default: '' },
    priceDefault: { type: Number, required: false, default: 0 },
    priceWas: { type: Number, default: 0 },
    priceCustomStyle: { type: String, default: '' },
    slug: { type: String, required: false, default: '' },
    headingTag: { type: String, default: 'p' },
    ctaLabel: { type: String, default: 'Zum Angebot' },
    modalContent: { type: String, default: '' },
    articleNumber: { type: String, default: '' },
    backgroundImages: { type: Object, default: null },
    isAdvertisement: { type: Boolean, default: false },
    customClass: { type: String, default: '' },
    discountBadgeType: { type: String, default: 'percent' },
    customBadgeMessage: { type: Object, default: () => {} },
    badgePosition: {
      type: String,
      default: 'right',
      validator: (value) => ['right', 'left'].includes(value),
    },
    isCtaButtonTransparent: { type: Boolean, required: false },
    showSmartHomeAward: { type: Boolean, required: false, default: false },
    offer: { type: Object, required: false, default: () => {} },
    energyLabelPosition: {
      type: Object,
      required: false,
      default: () => ({
        top: '45%',
        left: 'calc(50% - 65px)',
      }),
    },
    showFlyerAktionBanner: {
      type: Boolean,
      required: false,
    },
    badgeLegalModal: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      humanizePrice,
      isDiscountEven: true,
    };
  },
  computed: {
    product() {
      return this.offer?.product;
    },
    discount() {
      return this.discountBadgeType === 'percent'
        ? `${this.calculatePercentDiscount()}%`
        : `${this.calculateNominalDiscount()}€`;
    },
    link() {
      const path = this.slug || this?.product?.slug || '/shop';
      return path.startsWith('/') ? path : `/geraete/${path}`;
    },
    background() {
      const bgImage = this.backgroundImages;
      const images = [];
      if (bgImage) {
        if (bgImage.srcSmall) images.push(`--bg-image-xs: url(${bgImage.srcSmall});`);
        if (bgImage.srcMedium) images.push(`--bg-image-sm: url(${bgImage.srcMedium});`);
        if (bgImage.srcLarge) images.push(`--bg-image-md: url(${bgImage.srcLarge});`);
        return images.join('');
      }
      return 'background: #FFFFFF';
    },
    productTags() {
      if (!this.product) return [];
      let tags = [];
      if (this.product?.bundledProducts?.length > 0) {
        const { bundledProducts } = this.product;

        tags =
          bundledProducts[0]?.tags?.filter((tag) =>
            bundledProducts.every((product) => product?.tags.includes(tag))) || [];
      } else if (this.product?.tags) {
        tags = this.product.tags;
      }
      return tags.map((str) => str.toLowerCase());
    },
    isMzaCompatible() {
      return ['mzabasic', 'mzapro'].some((tag) => this.productTags.includes(tag));
    },
    brand() {
      return getBrand(this.offer);
    },
    rebateDescription() {
      const description = this.offer?.rebate?.description;
      if (description === 'no description') return null;
      return description;
    },
    rebateName() {
      return this.offer?.rebate?.name || '';
    },
    footnoteText() {
      const footnote = this.offer?.rebate?.footnote;
      if (!footnote) return null;
      return footnote;
    },
  },
  methods: {
    calculateNominalDiscount() {
      const diff = this.priceWas - this.priceDefault;
      const florred = Math.floor(diff);
      if (florred !== diff) {
        this.isDiscountEven = false;
      }
      return florred;
    },
    calculatePercentDiscount() {
      return this.priceWas > 0
        ? Math.floor((100 * (this.priceWas - this.priceDefault)) / this.priceWas).toFixed(0)
        : 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/base';

.offer-light {
  container: offer-tile / inline-size;
  height: 100%;
  position: relative;
  color: color(black);
  overflow: hidden;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
  border-radius: 9px;
  flex: 1;
  padding: $grid-base 0;

  &__content-container {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding-bottom: 12px;
  }
  &__badge {
    font-size: 16px;
    font-weight: 800;

    &--highlight {
      font-size: 30px;
      @include mq($mq-lg) {
        font-size: 36px;
      }
    }
    :deep(.badge__content) {
      padding: 0;
      min-width: 96px;
    }
    &--custom-message {
      width: min-content;
      font-size: 20px;
      padding: 0;
    }
    &--rebate {
      padding: calc($grid-base / 4);
    }
    &-legal-modal {
      margin: 0;
      border: 1.2px solid white;
      height: 11px;

      :deep(.legal-modal-button__button) {
        line-height: 20px;
        &:hover {
          background-color: transparent;
        }
        &:focus {
          background-color: transparent;
        }
      }
    }
  }

  &__backend-badge {
    :deep(.badge) {
      width: 96px;
      height: 96px;
      font-size: 0.7em;
      bottom: 66px;
      left: 5%;

      @include mq($mq-sm) {
        bottom: 90px;
      }

      .badge__content {
        width: 96px;
      }
    }
  }

  &__banner {
    position: absolute;
    top: 20px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &-offset {
      margin-right: 10px;
    }
  }

  &__background {
    background-image: var(--bg-image-xs);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    width: 100%;
    @include mq($mq-sm) {
      background-image: var(--bg-image-sm, var(--bg-image-xs));
    }
    @include mq($mq-md) {
      background-image: var(--bg-image-md, var(--bg-image-sm, var(--bg-image-xs)));
    }
  }

  &__heading {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    min-height: 80px;
    padding-inline: $grid-base;
    position: relative;
  }

  &__brand {
    @include mq($mq-md) {
      margin-bottom: calc($grid-base / 2);
    }

    > img {
      height: 22px;
      width: 146px;
    }

    &--mza {
      height: 36px;
      max-width: calc(100% - (36px * 2)); // save space for mza Icon

      @include mq($mq-md) {
        margin-bottom: 0;
      }
    }
  }

  &__mza-icon {
    position: absolute;
    right: $grid-base;
    top: 0;
  }

  &__title {
    width: 80%;
    max-width: 450px;
    margin: 0 auto;
    @include font-size(18px, 24px);
    @include mq($mq-sm) {
      max-width: none;
    }
    @include mq($mq-md) {
      @include font-size(24px, 29px);
    }
  }

  &__content {
    text-align: center;
    position: relative;
    width: 100%;
  }

  &__hero {
    display: flex;
    justify-content: center;
    gap: 5px;
    position: relative;
    margin-bottom: 2 * $grid-base;
    &-img {
      height: 225px;
      width: 246px;
      display: inline-block;
    }
    &-energy-label {
      position: absolute;
      @include font-size(21px, 21px);
      @include mq($mq-sm) {
        @include font-size(30px, 30px);
      }
      :deep(.energy-label-datasheet) {
        display: none;
      }
    }

    &--product {
      > img {
        height: 205px;
        width: auto;
        margin-block: 10px;
      }
    }
  }

  &__hero-placeholder {
    margin: 0 auto;
    height: 225px;
    width: auto;
  }
  &__cta {
    margin-bottom: $grid-base;
  }
  &__button {
    @include mq(false, $mq-sm - 1) {
      display: none;
    }
  }

  &__prices {
    padding-bottom: $grid-base;
    :deep(.prices__main) {
      justify-content: center;
      flex-direction: row-reverse;
      @include mq($mq-large, $mq-xl - 1) {
        grid-gap: 6px;
      }
    }
    :deep(.prices__is-price) {
      font-weight: 800;
    }
  }

  &__advertisement {
    background-position-x: 100px;
    background-position-y: 95px;
    &--content {
      text-align: center;
      @include mq($mq-sm) {
        text-align: center;
      }
      :deep(.offer-light__hero) {
        width: 100%;
      }
    }
  }

  &--wiz-lampe,
  &--eurotronic-heizspar-set,
  &--alarmschutz-set {
    @include mq($mq-md, $mq-lg - 1) {
      :deep(.prices__main) {
        flex-direction: column-reverse;
        grid-gap: 0;
        align-items: center;
      }

      :deep(.smarthome-award) {
        position: relative;
        right: $grid-base;
      }
    }
  }

  @container offer-tile (inline-size >= 360px) and (inline-size < 500px) {
    :deep(.offer-light__badge) {
      right: v-bind("badgePosition === 'right' ? '12px' : 'unset'");
      left: v-bind("badgePosition === 'right' ? 'unset' : '12px'");
    }
  }

  @container offer-tile (inline-size < 360px) {
    :deep(.offer-light__badge) {
      right: v-bind("badgePosition === 'right' ? '6px' : 'unset'");
      left: v-bind("badgePosition === 'right' ? 'unset' : '6px'");
    }
  }
}
</style>
