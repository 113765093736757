import { mapGetters } from 'vuex';
import Cookies from 'js-cookie';

// What is the correct default page type? undefined, theme.common or theme? As
// most pages use 'theme' we choose that as default.
const DEFAULT_PAGE_TYPE = 'theme';

function PageTracking(initOptions = {}) {
  const options = {
    trackViewOnMount: true,
    updateLinkTrackingOnMount: true,
    ...initOptions,
  };
  return {
    computed: {
      ...mapGetters('cart', ['isKnownCustomer', 'couponCampaignName', 'couponRedeemed']),
      tealiumPageType() {
        return DEFAULT_PAGE_TYPE;
      },
      tealiumPageContentIdFromPath() {
        return this.$route.path
          .split('/')
          .slice(1)
          .join('.');
      },
      tealiumPageContentId() {
        return this.tealiumPageContentIdFromPath;
      },
      tealiumCustomerType() {
        return this.isKnownCustomer ? 'bestandskunde' : 'nicht-bestandskunde';
      },
      shopVo() {
        return Cookies.get('TK_VO');
      },
      shopWb() {
        return Cookies.get('TK_WB');
      },
      tealiumData() {
        return {
          page_type: this.tealiumPageType,
          page_content_id: `www.smarthome.de.privatkunden.${this.tealiumPageContentId}`,
          page_technical_id: '',
          login_status: 'not-logged-in',
          shop_customer_type: this.tealiumCustomerType,
          ...this.additionalTealiumData,
          shop_mlid: '',
          shop_vb: '',
          shop_vo: this.shopVo,
          shop_wb: this.shopWb,
          shop_vpnr: '',
          shop_gutschein_kampagne: this.couponCampaignName,
          shop_gutschein_vorhanden: this.couponRedeemed ? 'ja' : 'nein',
        };
      },
      additionalTealiumData() {
        return {};
      },
    },
    mounted() {
      if (this.$tealium) {
        if (options.trackViewOnMount) {
          this.$tealium.view(this.tealiumData);
        }
        if (options.updateLinkTrackingOnMount) {
          this.$tealium.updateAutomaticLinkTracking();
        }
      }
    },
  };
}

PageTracking.Default = PageTracking();

export default PageTracking;
