import merge from 'lodash/merge';
import { clientWithLoadingIndicator as axios } from '@/lib/axios-client/content';
import { buildDate } from '@/lib/client-env';

export async function getContentAndHandleConfig(routePath, config, context) {
  try {
    const fullPath = `/api/content/data${routePath}`;
    const response = await axios.get(fullPath);
    const contentData = response.data;
    if (process.server) {
      const apiLastModified = new Date(response.headers['last-modified']);
      const lastModified = buildDate > apiLastModified ? buildDate : apiLastModified;
      context.res.setHeader('last-modified', lastModified.toUTCString());
    }
    if (typeof config.next === 'function') {
      const nextData = await config.next(context);
      return merge(contentData, nextData);
    }
    return contentData;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      const notFoundStatusCode = config.notFoundStatusCode || 404;
      return context.error({ statusCode: notFoundStatusCode, message: 'content data not found' });
    }
    return context.error({ statusCode: 500, message: error.message });
  }
}

export default function contentDataFactory(config = {}) {
  return async function asyncContentData(context) {
    let routePath = config.routePath || context.route.path;
    if (routePath.endsWith('/')) {
      routePath += 'index';
    }
    if (!routePath.startsWith('/')) {
      routePath = `/${routePath}`;
    }
    return getContentAndHandleConfig(routePath, config, context);
  };
}

export function contentDataFor(path, schema, config = {}) {
  return async function asyncContentData(context) {
    return getContentAndHandleConfig(`/${path}?schema=${schema}`, config, context);
  };
}
