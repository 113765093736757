<template>
  <div :class="`mza mza--${size} mza--${direction}`" @click.prevent.stop="toggleInnerText">
    <div class="mza__collapsible">
      <div class="mza__collapsible-inner" :class="showInnerTextClass">
        <div class="mza__collapsible__text">
          <span>works with <br /><span class="mza__collapsible__text--highlight">MagentaZuhause App</span>
          </span>
        </div>
        <SvgIcon class="icon mza__icon--telekom" :image="TLogo" />
      </div>
    </div>
    <SvgIcon class="icon mza__icon" :image="mzaIcon" />
  </div>
</template>

<script>
import SvgIcon from './svg-icon';
import mzaIcon from '@/assets/images/mza-relaunch/mza-logo-magenta-solid.svg';
import TLogo from '@/assets/images/t-logo.svg';

export default {
  name: 'MzaIcon',
  components: {
    SvgIcon,
  },
  props: {
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return ['small', 'medium'].includes(value);
      },
    },
    direction: {
      type: String,
      default: 'left',
      validator(value) {
        return ['left', 'right'].includes(value);
      },
    },
  },
  data() {
    return {
      isHovered: false,
      mzaIcon,
      TLogo,
    };
  },
  computed: {
    showInnerTextClass() {
      return {
        'mza__collapsible-inner-open': this.isHovered,
        'mza__collapsible-inner-close': !this.isHovered,
      };
    },
  },
  methods: {
    toggleInnerText() {
      this.isHovered = !this.isHovered;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/base';

.mza {
  $size-sm: 2 * $grid-base;
  $size-md: 3 * $grid-base;

  height: $size-md;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;

  &__collapsible {
    overflow: hidden;
    &-inner {
      flex-direction: row;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      background-color: color(magenta, 1000);
      padding: 0 15px 0 $grid-base;
      height: calc($size-md - 2px);
      transition: all 0.3s ease;
    }
    &__text {
      width: max-content;
      text-align: left;
      color: color(white);
      @include font-size(12px, 12px);
      display: flex;
      align-items: center;
      &--highlight {
        font-weight: 600;
      }
    }
  }
  &__icon {
    width: $size-md;
    height: $size-md;
    z-index: 1;
    &--telekom {
      height: 19px;
      width: auto;
    }
  }

  &--small {
    height: $size-sm;

    .mza {
      &__icon {
        width: $size-sm;
        height: $size-sm;
        &--telekom {
          height: 14px;
        }
      }

      &__collapsible {
        &-inner {
          height: calc($size-sm - 2px);
        }

        &__text {
          @include font-size(8px, 8px);
        }
      }
    }
  }

  &--left {
    @media (hover: hover) {
      &:hover {
        .mza__collapsible-inner {
          margin-right: -5px;
        }
      }
    }
    .mza__collapsible {
      margin-right: -5px;

      &-inner {
        margin-right: -5px;

        @include mq($mq-sm) {
          margin-right: -200px;
        }
      }

      &-inner-open {
        @include mq(false, $mq-md - 1) {
          margin-right: -5px;
        }
      }
      &-inner-close {
        @include mq(false, $mq-md - 1) {
          margin-right: -200px;
        }
      }
    }
  }

  &--right {
    flex-direction: row-reverse;
    position: relative;

    @media (hover: hover) {
      &:hover {
        .mza__collapsible-inner {
          margin-left: -5px;
        }
      }
    }
    .mza__collapsible {
      margin-left: -5px;
      position: absolute;
      left: $size-md;
      &-inner {
        flex-direction: row-reverse;
        margin-left: -5px;

        @include mq($mq-sm) {
          margin-left: -200px;
        }
      }

      &-inner-open {
        @include mq(false, $mq-md - 1) {
          margin-left: -5px;
        }
      }
      &-inner-close {
        @include mq(false, $mq-md - 1) {
          margin-left: -200px;
        }
      }
    }

    &.mza--small {
      .mza__collapsible {
        left: $size-sm;
      }
    }
  }
}
</style>
