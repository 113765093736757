<template>
  <div
    class="offer-light offer-light__background"
    :style="background"
    :class="[{ 'offer-light__advertisement': isAdvertisement }, `offer-light__${slug}`]"
  >
    <a class="offer-light__content-container" :href="link">
      <div class="offer-light__heading">
        <CdnImageElement
          v-if="getBrandDetails.logo"
          class="offer-light__brand"
          :src="getBrandDetails.logo"
          :alt="getBrandDetails.name"
          render-without-figure
          lazy
        />
        <heading
          v-if="copy"
          class="offer-light__sub-heading"
          :tag="headingTag"
          size="md"
          :text="copy"
          font-weight="ultra"
        />
        <heading
          class="offer-light__title"
          :tag="headingTag"
          size="md"
          font-weight="ultra"
          align="center"
        >
          {{ title }}
        </heading>

        <MzaIcon v-if="isMzaCompatible" class="offer-light__mza-icon" />
      </div>
      <div
        class="offer-light__content"
        :class="{ 'offer-light__advertisement--content': isAdvertisement }"
      >
        <div v-if="showFlyerAktionBanner" class="offer-light__banner">
          <FlyerAktionBanner text="Telekom" />
          <FlyerAktionBanner text="Flyer-Aktion" class="offer-light__banner-offset" />
        </div>
        <div v-if="hero" class="offer-light__hero">
          <div class="offer-light__img-container">
            <CdnImageElement
              class="offer-light__hero-img"
              :style="heroCustomStyle"
              :src="hero"
              :alt="title"
              lazy
              render-without-figure
            />
          </div>
        </div>

        <div v-else class="offer-light__hero-placeholder"></div>
        <Badge
          v-if="customBadgeMessage || priceWas"
          class="offer-light__badge"
          :position-default="{ top: '12px', [badgePosition]: '7%' }"
          :position-mobile="{ top: '12px', [badgePosition]: '10%' }"
        >
          <span
            v-if="customBadgeMessage"
            class="offer-light__badge--custom-message"
            v-html="customBadgeMessage.text"
          >
          </span>
          <div v-else class="offer-light__badge--rebate">
            <span v-if="!isDiscountEven">über <br /> </span>
            <span class="offer-light__badge--highlight"> {{ discount }}</span><br />
            sparen
          </div>
        </Badge>
        <Prices
          v-if="priceDefault"
          class="offer-light__prices"
          :style="priceCustomStyle"
          pre-text-is-price="nur"
          :is-price="humanizePrice(priceDefault, { hideTrailingZeros: true })"
          :was-price="priceWas ? humanizePrice(priceWas) : ''"
        />
      </div>
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { Heading, Prices, Badge } from '@i22-td-smarthome/component-library';
import { getBrand } from '@/lib/brand';
import { humanizePrice } from '@i22/rocket';
import CdnImageElement from '@/components/cdn-image-element';
import MzaIcon from '@/components/mza-icon';
import FlyerAktionBanner from '@/components/flyer-aktion-banner';

export default {
  name: 'OfferLightCustom',
  components: {
    Heading,
    CdnImageElement,
    Prices,
    Badge,
    MzaIcon,
    FlyerAktionBanner,
  },
  props: {
    title: { type: String, required: true },
    copy: { type: String, default: '' },
    hero: { type: String, required: false, default: '' }, // hero image can be integrated with background
    heroCustomStyle: { type: String, default: '' },
    priceDefault: { type: Number, required: false, default: 0 },
    priceWas: { type: Number, default: 0 },
    priceCustomStyle: { type: String, default: '' },
    slug: { type: String, required: false, default: '' },
    headingTag: { type: String, default: 'p' },
    ctaLabel: { type: String, default: 'Mehr erfahren' },
    modalContent: { type: String, default: '' },
    articleNumber: { type: String, default: '' },
    backgroundImages: { type: Object, default: null },
    isAdvertisement: { type: Boolean, default: false },
    discountBadgeType: { type: String, default: 'percent' },
    customBadgeMessage: { type: Object, default: () => {} },
    badgePosition: {
      type: String,
      default: 'right',
      validator: (value) => ['right', 'left'].includes(value),
    },
    isCtaButtonTransparent: { type: Boolean, required: false },
    showSmartHomeAward: { type: Boolean, required: false, default: false },
    offer: { type: Object, required: false, default: () => {} },
    energyLabelPosition: {
      type: Object,
      required: false,
      default: () => ({
        top: '45%',
        left: '30%',
      }),
    },
    showFlyerAktionBanner: {
      type: Boolean,
      required: false,
    },
    brand: { type: Object, default: () => {} },
  },
  data() {
    return {
      humanizePrice,
      isDiscountEven: true,
    };
  },
  computed: {
    ...mapGetters('viewport', ['isMobileScreen']),
    product() {
      return this.offer?.product;
    },
    discount() {
      return this.discountBadgeType === 'percent'
        ? `${this.calculatePercentDiscount()}%`
        : `${this.calculateNominalDiscount()}€`;
    },
    link() {
      const path = this.slug || this?.product?.slug || '/shop';
      return path.startsWith('/') ? path : `/geraete/${path}`;
    },
    background() {
      const bgImage = this.backgroundImages;
      const images = [];
      if (bgImage) {
        if (bgImage.srcSmall) images.push(`--bg-image-xs: url(${bgImage.srcSmall});`);
        if (bgImage.srcMedium) images.push(`--bg-image-sm: url(${bgImage.srcMedium});`);
        if (bgImage.srcLarge) images.push(`--bg-image-md: url(${bgImage.srcLarge});`);
        return images.join('');
      }
      return 'background: #FFFFFF';
    },
    productTags() {
      if (!this.product) return [];
      let tags = [];
      if (this.product?.bundledProducts?.length > 0) {
        const { bundledProducts } = this.product;

        tags =
          bundledProducts[0]?.tags?.filter((tag) =>
            bundledProducts.every((product) => product?.tags.includes(tag))) || [];
      } else if (this.product?.tags) {
        tags = this.product.tags;
      }
      return tags.map((str) => str.toLowerCase());
    },
    isMzaCompatible() {
      return ['mzabasic', 'mzapro'].some((tag) => this.productTags.includes(tag));
    },
    getBrandDetails() {
      let brand = getBrand(this.offer);

      if (this.brand) {
        if (!brand) {
          brand = this.brand;
        } else {
          brand = { ...brand, ...this.brand };
        }
      }
      return brand;
    },
  },
  methods: {
    calculateNominalDiscount() {
      const diff = this.priceWas - this.priceDefault;
      const florred = Math.floor(diff);
      if (florred !== diff) {
        this.isDiscountEven = false;
      }
      return florred;
    },
    calculatePercentDiscount() {
      return this.priceWas > 0
        ? Math.floor((100 * (this.priceWas - this.priceDefault)) / this.priceWas).toFixed(0)
        : 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/base';

.offer-light {
  container: offer-tile / inline-size;
  height: 100%;
  position: relative;
  color: $color-font;
  overflow: hidden;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
  border-radius: 9px;
  flex: 1;
  padding: $grid-base 0;
  cursor: pointer;

  @include ie-only() {
    flex-direction: inherit;
    justify-content: center;
  }

  &__img-container {
    width: 60%;
    max-width: 300px;

    @include mq($mq-md) {
      height: 460px;
      margin-top: 20%;
    }

    @include mq(1070px) {
      margin-top: 2 * $grid-base;
    }
  }

  &__content-container {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 12px;
    color: $color-font;
    justify-content: space-between;
  }
  &__badge {
    font-size: 16px;
    font-weight: 800;

    @include mq($mq-sm) {
      font-size: 27px;
    }
    &--highlight {
      font-size: 36px;

      @include mq($mq-sm) {
        font-size: 60px;
      }
    }

    :deep(.badge__content) {
      padding: 0;
      min-width: 108px;

      @include mq($mq-sm) {
        padding: 3 * $grid-base;
      }
    }
    &--custom-message {
      width: min-content;
      font-size: 20px;
      padding: 0;
    }
    &--rebate {
      padding: calc($grid-base / 4);
    }
  }

  &__background {
    background-image: var(--bg-image-xs);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    width: 100%;
    @include mq($mq-sm) {
      background-image: var(--bg-image-sm, var(--bg-image-xs));
    }
    @include mq($mq-md) {
      background-image: var(--bg-image-md, var(--bg-image-sm, var(--bg-image-xs)));
    }
  }

  &__heading {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: flex-start;
    min-height: 80px;
    padding: $grid-base * 2;
    padding-bottom: 0;
  }
  &__sub-heading {
    padding: $grid-base 0;
  }
  &__brand {
    height: 22px;
    width: auto;
    @include mq($mq-md) {
      margin-bottom: calc($grid-base / 2);
    }
  }
  &__mza-icon {
    position: absolute;
    top: 12px;
    right: 12px;
  }
  &__title {
    max-width: 450px;
    @include font-size(18px, 24px);
    @include mq($mq-sm) {
      max-width: none;
    }
    @include mq($mq-md) {
      @include font-size(24px, 29px);
    }
  }

  &__content {
    text-align: center;
    position: relative;
    width: 100%;
  }

  &__hero {
    display: flex;
    justify-content: center;
    gap: 5px;
    position: relative;
    margin-top: 6 * $grid-base;

    @include mq($mq-sm) {
      margin-top: 0;
    }
    &-img {
      aspect-ratio: auto;
      display: inline-block;
      margin-top: $grid-base;
      position: relative;
      @include mq($mq-sm) {
        margin-top: $grid-base * 5;
      }
      @include mq($mq-md) {
        margin-top: 45%;
      }
    }
  }

  &__banner {
    position: absolute;
    bottom: 25%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;

    @include mq($mq-md) {
      bottom: 30%;
    }

    &-offset {
      margin-right: 10px;
    }
  }

  .flyer-aktion__container {
    font-size: 18px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    padding: 0 20px 0 40px;

    @include mq($mq-sm) {
      font-size: 24px;
      padding: 0 20px 0 80px;
    }
  }

  &__app {
    &-download {
      display: flex;
      align-items: flex-start;
      gap: $grid-base;
      padding: 0 2 * $grid-base;

      :deep(.get-app) {
        margin-top: 2px;

        @include mq($mq-sm) {
          justify-content: flex-end;
        }
      }

      @include mq($mq-sm) {
        flex-direction: column;
        align-items: flex-end;
        position: absolute;
        bottom: 0;
        left: initial;
        text-align: right;
        right: 0;
      }
    }
    &-copy {
      @include mq($mq-sm) {
        text-align: right;
        max-width: 200px;
      }
    }
  }

  &__hero-placeholder {
    margin: 0 auto;
    height: 225px;
    width: auto;
  }
  &__cta {
    margin-bottom: $grid-base;
  }
  &__button {
    @include mq(false, $mq-sm - 1) {
      display: none;
    }
  }

  &__prices {
    padding: $grid-base * 3 0 $grid-base $grid-base * 2;
    :deep(.prices__main) {
      justify-content: flex-end;
      flex-direction: row-reverse;
    }
    :deep(.prices__is-price) {
      font-weight: 800;
    }
  }
  &__buttons {
    display: none;
    @include mq($mq-sm) {
      display: flex;
      gap: $grid-base;
      padding: $grid-base 0 0 $grid-base * 2;
    }
  }
  &__advertisement {
    background-position-x: 100px;
    background-position-y: 95px;
    &--content {
      text-align: center;
      @include mq($mq-sm) {
        text-align: center;
      }
      :deep(.offer-light__hero) {
        width: 100%;
      }
    }
  }

  @container offer-tile (inline-size >= 360px) and (inline-size < 500px) {
    :deep(.offer-light__badge) {
      right: v-bind("badgePosition === 'right' ? '12px' : 'unset'");
      left: v-bind("badgePosition === 'right' ? 'unset' : '12px'");
    }
  }

  @container offer-tile (inline-size < 360px) {
    :deep(.offer-light__badge) {
      right: v-bind("badgePosition === 'right' ? '6px' : 'unset'");
      left: v-bind("badgePosition === 'right' ? 'unset' : '6px'");
    }
  }
}
</style>
