<template>
  <div class="mza-qr-modal">
    <LinkButton
      tag="button"
      :primary="primary"
      :transparent="transparent"
      @click="$refs.modal.open('mza-qr-modal')"
    >
      App via QR-Code laden
    </LinkButton>

    <Modal ref="modal">
      <div class="mza-qr-modal__modal">
        <Heading tag="h3" size="sm" class="mza-qr-modal__heading" :text="modal.heading" align="center" font-weight="ultra" spaces-bottom />
        <div class="mza-qr-modal__copy">
          <CdnImageElement
            render-without-figure
            :src="modal.image.src"
            :alt="modal.image.alt"
            class="mza-qr-modal__image"
          />
          <ul class="mza-qr-modal__list">
            <li v-for="(item, index) in modal.copy" :key="item" class="mza-qr-modal__list-item">
              <span class="mza-qr-modal__list-number">{{ index + 1 }}</span>
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import {
  LinkButton,
  Heading,
} from '@i22-td-smarthome/component-library';
import Modal from '@/components/modal';
import CdnImageElement from '@/components/cdn-image-element';

export default {
  name: 'MzaQrModal',
  components: {
    Modal,
    LinkButton,
    Heading,
    CdnImageElement,
  },
  props: {
    transparent: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modal: {
        heading: 'Jetzt kostenlos die MagentaZuhause App laden',
        copy: ['Öffne die Kamera oder den QR-Scanner deines Smartphones', 'Scanne den QR-Code', 'Lade die App herunter'],
        image: {
          src: '/api/content/images/8f8cb953252a75dacba550f08f0b24da/qr-code-magenta-zuhause.png',
          alt: 'QR Code für die MagentaZuhause App',
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
 @import "assets/base";

  .mza-qr-modal {
    &__modal {
      padding: $grid-base 0;

      @include mq($mq-sm) {
        padding: 0 $grid-base * 4 $grid-base;
      }
    }

    &__copy {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2 * $grid-base;
      max-width: 460px;

      @include mq($mq-sm) {
        flex-direction: row;
        justify-content: space-between;
      }
    }

    &__image {
      width: 170px;
      height: auto;
    }

    &__list {
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 2 * $grid-base;
      max-width: 260px;

      &-item {
        display: flex;
        gap: $grid-base;
      }

      &-number {
        background-color: color(magenta, 1000);
        border-radius: 50%;
        color: color(white);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 27px;
        min-width: 27px;
        width: 27px;
        @include font-size(18px, 27px);
      }
    }
  }
</style>
