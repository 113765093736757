import { clientWithLoadingIndicator as axios } from '@/lib/axios-client/goliath';
import { captureException } from '@/plugins/sentry';

/**
 * Will return the Goliath response or the default response, when an error occurs.
 * All Errors will be logged
 *
 * @param url {string}
 * @param params {object}
 * @param defaultResponse {string | object | array | undefined | null}
 * @return {Promise<*[]|*>}
 */
export default async (url, params, defaultResponse = []) => {
  try {
    const response = await axios.post(url, params).catch((e) => {
      captureException(e);
    });
    if (!response) return defaultResponse;
    if (response.data?.errors) {
      captureException(response.data.errors);
      return defaultResponse;
    }
    // server has a 500 and returns "internal server error" as body.
    if (typeof response.data === 'string') {
      captureException(response.data);
      return defaultResponse;
    }
    return response.data.data;
  } catch (e) {
    captureException(e);
  }
  return defaultResponse;
};
